/*
 * Typography
 */
$font--display-family: 'Montserrat', sans-serif;
$font--base-family: 'Work Sans', sans-serif;
$font--base-size: 16px;
$inner-container-size: 550px;

/*
* Colors
*/
$color--white: #fff;
$color--black: #000;
$color--blue: #262aff;
$color--typography-base: $color--white;
$color--background: $color--blue;

@keyframes MOVE-MOUTH {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes SPIN-MOUTH {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

body {
  background-color: $color--blue;
  line-height: 1;
  color: $color--typography-base;
  text-align: left;
  font-family: $font--base-family;
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  font-family: $font--display-family;
  font-weight: 400;
}

a {
  color: $color--typography-base;
}

h1 {
  font-size: 24px;
  letter-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  padding-right: 40px;
  margin-bottom: 20px;
  max-width: $inner-container-size;
}

h2 {
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  max-width: $inner-container-size;
  margin-bottom: 30px;
  color: #bbbbbb;
}

.container {
  position: relative;
  max-width: 780px;
  margin: 0 auto;
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: $inner-container-size;
    letter-spacing: 0;
    line-height: 21px;
  }
}

.mouth--left {
  position: absolute;
  right: 40px;
  top: 20px;
}

.mouth--right {
  position: absolute;
  right: 0;
  top: 810px;
}

.input-container {
  background-image: url(/img/input-background-large.svg);
  background-size: 617px 314px;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 100px 40px 40px;
  text-align: center;
  position: relative;
  margin-top: 80px;
}

.input-container__mouth {
  position: absolute;
  left: -10px;
  top: -70px;
  animation: MOVE-MOUTH 2s infinite;
}

textarea {
  background-color: transparent;
  resize: none;
  border: 0;
  font-size: 1.1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 180px;
  color: $color--blue;
  font-weight: 600;

  &:active,
  &:focus {
    outline: none;
    border: 0;
  }

  &:disabled {
    color: #666;
  }
}

.input__results {
  padding-top: 50px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.btn--primary {
  background: url('/img/primary-button.svg');
  color: $color--blue;
  width: 150px;
}

.btn--secondary {
  color: $color--white;
  background: url(/img/secondary-button.svg);
  width: 180px;
}

.btn {
  padding-top: 2px;
  background-repeat: no-repeat;
  height: 45px;
  border: 0;
  font-family: $font--base-family;
  font-size: 15px;
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.js-test-results {
  display: none;

  &.active {
    display: block;
  }
}

.img__from-us {
  width: 286px;
}

.pass {
  color: green;
}

.fail {
  color: red;
  text-decoration: line-through;
}

footer {
  img {
    max-width: 100%;
    width: 100%;
  }

  margin-top: 60px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 650px;
  text-align: center;
}

.footer__divider {
  margin-bottom: 20px;
}

.input__form.loading {
  .input-container__mouth {
    animation: SPIN-MOUTH 2s infinite;
  }
}

@media only screen and (min-width: 780px) {
  h1 {
    margin-left: auto;
    margin-right: auto;
    font-size: 39px;
    max-width: $inner-container-size;
  }

  .input-container {
    background-size: 100%;
    width: 680px;
    padding: 100px 40px 40px;
  }

  .container {
    padding-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }

  textarea {
    width: $inner-container-size;
    height: 220px;
    max-width: 550px;
  }

  .input__results {
    padding-top: 0;
    text-align: right;
    position: absolute;
    right: 0;
    top: -40px;
  }

  .mouth--left {
    top: 90px;
  }

  .mouth--right {
    top: 940px;
  }

  .input-container__mouth {
    width: 109px;
    top: -70px;
  }

  .btn {
    margin-bottom: 30px;
  }
}
